<template>
  <div class="form__field" :class="{ 'form__field--error': error || uploadError, 'form__field--columns': isColumned }">
    <label v-if="label" class="form__field__label input-label" :for="uid">
      {{ label }} <small v-if="notRequired">необязательно</small>
    </label>
    <div class="form__field__inner">
      <div class="form__field__uploader">
        <input class="form__field__input" type="file" :id="uid" @change="upload($event.target.files[0])">
        <label class="form__field__input-wrap" :for="uid">
          <div class="form__field__progress-wrap">
            <div class="form__field__progress"
                 :class="{ 'form__field__progress--active': uploading }"
                 :style="{ transform: `scaleX(${progress})` }"></div>
          </div>
          <div v-if="value?.id" class="form__field__input">{{ value?.filename }} ({{ formatFileSize(value?.size) }})</div>
          <div v-else-if="file" class="form__field__input">{{ file?.name }} ({{ formatFileSize(file?.size) }}</div>
          <div v-else class="form__field__input input">Не выбран файл</div>
          <slot name="icon"></slot>
        </label>
        <label class="button" :for="uid" tabindex="0">Обзор</label>
      </div>
      <small v-if="uploadError || error" class="form__field__caption form__field__caption--error">{{ uploadError || error }}</small>
      <small v-if="$slots.hint" class="form__field__caption">
        <slot name="hint"></slot>
      </small>
      <slot name="afterInput"></slot>
    </div>
  </div>
</template>

<script setup>
import { storeFile } from '@/api/user'
import { formatFileSize } from '~/utils/formatFileSize'

const props = defineProps({
  label: String,
  modelValue: [String, Number, Object],
  error: String,
  columns: Boolean,
  placeholder: String,
  onMounted: Function,
  notRequired: Boolean,
  type: String
})
const emit = defineEmits(['update:modelValue'])

const file = ref(null)
const uploading = ref(false)
const progress = ref(0)
const uploadError = ref(null)
const uid = 'input' + getCurrentInstance().uid

const columns = false
const isColumned = computed(() => props.columns || columns.value)

const value = computed({
  get: () => props.modelValue,
  set: val => emit('update:modelValue', val)
})

async function upload (file) {
  if (uploading.value) {
    return
  }

  if (!file) {
    value.value = null
    return
  }

  file.value = file
  uploadError.value = null
  uploading.value = true
  progress.value = 0
  try {
    value.value = await storeFile(file, event => {
      progress.value = event.loaded / event.total
    }, props?.type === 'image')
  } catch (error) {
    if (error.data?.errors?.file) uploadError.value = error.data.errors.file
    else uploadError.value = 'Слишком большой файл'
    value.value = undefined
  }
  uploading.value = false
  file.value = null
}
</script>
